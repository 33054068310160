
import "./CardHeader.css"
import {Container, Row, Col } from 'react-bootstrap'

function CardHeader() {

    return (
        <header className="header p-3 text-light">
            LOGOTYP
            {/* <img style={{maxWidth: "250px"}} src="logonectarina.svg" className="max-width: 250px" alt="Nectarina | Advertising Point"></img> */}
        </header>
        
    )

}

export default CardHeader;